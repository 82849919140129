@import '~@blueprintjs/core/lib/scss/variables';

// $pt-button-height: 26px;
// $pt-input-height: 26px;
// $pt-border-radius: 3px;

@import '~@blueprintjs/core/src/blueprint';
@import '~@blueprintjs/select/src/blueprint-select';

@font-face {
  font-family: 'blueprint-icons-16';
  src:
    url('./icons/blueprint/blueprint-icons-16.ttf?0c7462c47bcd60a8d9e9dc959711c752')
      format('truetype'),
    url('./icons/blueprint/blueprint-icons-16.eot?0c7462c47bcd60a8d9e9dc959711c752#iefix')
      format('embedded-opentype'),
    url('./icons/blueprint/blueprint-icons-16.woff2?0c7462c47bcd60a8d9e9dc959711c752')
      format('woff2'),
    url('./icons/blueprint/blueprint-icons-16.woff?0c7462c47bcd60a8d9e9dc959711c752')
      format('woff');
}

.bp4-control {
  margin-bottom: 0;
}
